<template>
  <div>
    <div class="row">
      <!--begin::Detail-->
      <b-card
        header-tag="header"
        footer-tag="footer"
        class="gutter-b col-md-4"
        v-if="!isLoading"
      >
        <div class="mb-6 text-center">
          <img
            style="object-fit: scale-down"
            width="150px"
            :src="
              employee.photo
                ? employee.photo
                : 'https://gardtrax-admin.tdpindonesia.id/dist/img/user-avatar.png'
            "
          />
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Nama :</span>
          <span class="text-muted">{{ employee.name }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Tempat Lahir :</span>
          <span class="text-muted">{{ employee.birth_place }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Jenis Kelamin :</span>
          <span class="text-muted">{{
            employee.gender === "L" ? "Laki-Laki" : "Perempuan"
          }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">No E-KTP : </span>
          <span class="text-muted">{{ employee.id_number }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">E-Mail :</span>
          <span class="text-muted" v-if="employee?.user_profile">{{
            employee?.user_profile?.email
          }}</span>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Nama Bank :</span>
          <span class="text-muted">Bank Mandiri</span>
        </div> -->
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Suku :</span>
          <span class="text-muted">{{ employee.ethnic }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> Status Perkawinan :</span>
          <span class="text-muted"> {{ employee.marital_status }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> Alamat :</span>
          <span class="text-muted"> {{ employee.address }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> Tanggal Lahir :</span>
          <span class="text-muted"> {{ employee.dob }} </span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> Golongan Darah :</span>
          <span class="text-muted"> {{ employee.blood_type }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> No. Kartu Keluarga :</span>
          <span class="text-muted"> {{ employee.family_card_number }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> No. Telepon :</span>
          <span class="text-muted"> {{ employee.phone }}</span>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> No. Rekening :</span>
          <span class="text-muted"> {{ }}</span>
        </div> -->
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> Agama :</span>
          <span class="text-muted"> {{ employee.religion }} </span>
        </div>
      </b-card>

      <b-card class="gutter-b col-md-4">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Customer :</span>
          <span class="text-muted" v-if="employee?.customer_branch">{{
            employee.customer_branch.name
          }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">No. NPWP :</span>
          <span class="text-muted">{{ employee.npwp_number }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">NIP : </span>
          <span class="text-muted">{{ employee.nip }}</span>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Divisi :</span>
          <span class="text-muted">{{ employee.divisi }}</span>
        </div> -->
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Jabatan :</span>
          <span class="text-muted" v-if="employee?.role">{{
            employee.role.name
          }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-10">
          <span class="font-weight-bold">Shift :</span>
          <span class="text-muted" v-if="employee?.shift">{{
            employee.shift.name
          }}</span>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> Area Kerja :</span>
          <span class="text-muted"> {{ employee.working_area }}</span>
        </div> -->
      </b-card>
      <b-card class="gutter-b col-md-4">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">No. Kontrak :</span>
          <span class="text-muted">{{ employee.contract_number }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Mulai Kontrak :</span>
          <span class="text-muted">{{ employee.contract_start }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">No. KTA : </span>
          <span class="text-muted">{{ employee.kta_number }}</span>
        </div>
        <!-- <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Keluar/Resign :</span>
          <span class="text-muted">{{}}</span>
        </div> -->
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Status Kontrak :</span>
          <span class="text-muted">{{ employee.contract_status }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold">Akhir Kontrak :</span>
          <span class="text-muted">{{ employee.contract_end }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between mb-3">
          <span class="font-weight-bold"> Jenis KTA :</span>
          <span class="text-muted"> {{ employee.kta_type }}</span>
        </div>
      </b-card>
      <!--end::Detail-->
    </div>
    <!-- <b-button variant="success">Download PDF</b-button> -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "DetailEmployee",
  data() {
    return {
      selected: null,
      isLoading: false,
      employee: {},
    };
  },
  components: {},
  mounted() {
    this.getData();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Employee", route: "/employee" },
      { title: "View" },
    ]);
  },
  methods: {
    async getData() {
      this.isLoading = true;
      try {
        const idEmployee = this.$route.params.id;
        await this.$axios
          .get(
            `${process.env.VUE_APP_BASE_API_GARDTREX}/v1/cms/employee/detail/${idEmployee}`,
            {
              params: this.params,
            }
          )
          .then(({ data }) => {
            this.employee = data;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message ?? "Terjadi Kesalahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss"></style>
